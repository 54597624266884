@import url("https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply cursor-default font-display overflow-x-hidden;
  }

  h1,
  h2 {
    @apply dark:text-white;
  }
}

@layer utilities {
  .word-break-word {
    word-break: break-word;
  }

  .word-wrap-break {
    word-wrap: break-word;
  }
}

:root {
  --master-clr-accent: #818cf8;
  --border-color: #e1e1e1;
  --border-width: 1px;
  --gap: clamp(16px, 4vh, 1.5em);
  --top-gap: calc(var(--gap) / 2);
}

html,
body,
#root {
  height: 100%;
}

body {
  @apply dark:bg-background font-light dark:text-gray-300 overflow-x-hidden;

  &::-webkit-scrollbar {
    width: 6px;
    z-index: 100;
    @apply bg-background;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-primary-400 rounded-full;
  }
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.navbar-custom .navbar-brand,
.navbar-custom .navbar-text,
.navbar-custom .nav-link {
  color: var(--master-clr-light);
}

.navbar-custom .nav-link:hover {
  @apply text-primary-400;
}

.h-100 {
  height: 100vh !important;
  @media only screen and (max-width: 768px) {
    height: 50vh !important;
  }
}

.h-40rem {
  height: 40rem !important;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    @apply border-primary-400;
  }
}

.typingEffect {
  letter-spacing: 0.15em;
  margin: 0 auto;
  white-space: nowrap;
  border-right: 0.15em solid;
  @apply border-primary-400;
  overflow: hidden;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
  -webkit-animation: typing 3.5s steps(40, end),
    blink-caret 0.75s step-end infinite;
}

.CursorBlink {
  &:after {
    content: "|";
    @apply animate-cursorBlink;
  }
}

.avatar {
  background-image: url("../src/assets/imgs/me.png");
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center;
  background-size: cover;
  vertical-align: middle;
  border-radius: 50%;
  position: relative;
  box-shadow: inset 0 0 0 1000px rgb(0 0 0 / 30%);
}

.avatar-w-h-300 {
  width: 300px;
  height: 300px;
}

.avatar-w-h-200 {
  width: 200px;
  height: 200px;
}

.avatar-w-h-150 {
  width: 150px;
  height: 150px;
}

.hoverBox:hover {
  border-radius: 1rem;
  // background: linear-gradient(
  //   40deg,
  //   transparent,
  //   10%,
  //   rgba(255, 255, 255, 0.16) 60%,
  //   transparent 100%
  // );
  transition: all 200ms;
  background: rgba(0, 0, 0, 0.25);
}

.MosaicLayout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 1fr));
  grid-gap: 1rem;
}

.progress {
  display: flex;
  gap: 1px;
  background-color: transparent;
  overflow: hidden;
  .tak {
    width: 2rem;
    @apply bg-primary-400;

    height: 1rem;
  }

  .inTacked01 {
    animation: inTacked 1.5s infinite;
    animation-delay: 0.5s;
  }

  .inTacked02 {
    animation: inTacked 1.5s infinite;
    animation-delay: 1s;
  }

  .inTacked03 {
    animation: inTacked 1.5s infinite;
    animation-delay: 1.5s;
  }
}

@keyframes inTacked {
  from {
    @apply bg-primary-400;
  }
  to {
    background-color: transparent;
  }
}

.timeLineContainer {
  display: flex;
  flex: auto;
  overflow-y: auto;
  width: 100%;
}

.timeLine {
  position: relative;
  height: 100%;
  width: 100%;

  div {
    &:after {
      content: "";
      width: 1px;
      position: absolute;
      top: 25px;
      bottom: 0rem;
      left: 100px;
      z-index: 1;
      background: white;
    }
  }

  section.year {
    display: flex;
    position: relative;
    gap: 1rem;

    .leftSide {
      min-width: 100px;
    }
    section {
      padding-bottom: 0px;
      position: relative;
      margin-bottom: 5rem;

      &:after {
        content: "";
        width: 12px;
        height: 12px;
        @apply bg-primary-400;
        border-radius: 50%;
        position: absolute;
        top: 9px;
        left: -21px;
        z-index: 2;
      }
    }
  }
}

.text-align-justify {
  text-align: justify;
}

.bg-shadow {
  background: rgb(24 74 124 / 42%);
  color: white !important;
}

.lines {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: -1;
}

.line {
  position: relative;
  width: 1px;
  height: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 150px;
    width: 100%;
    top: -50%;
    left: 0;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 75%,
      #ffffff 100%
    );
    animation: dropDown 7s 0s infinite;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
  }
  &:nth-child(1) {
    &::after {
      animation-delay: 2s;
    }
  }
}

@keyframes dropDown {
  from {
    top: -50%;
  }
  to {
    top: 110%;
  }
}

.pointer {
  cursor: pointer;
}

.beta-label {
  margin-left: 10px;
  display: inline-block;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 4px;
  @apply bg-primary-400 text-black;
}

//BLOG
.blog-post {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.post-category {
  background: #fff;
  border: 1px solid #000;
  display: inline-block;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 14px;
  padding: 9px 15px 8px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;

  &::before {
    background: #000;
    bottom: -5px;
    content: "";
    display: block;
    position: absolute;
  }

  &::after {
    height: 5px;
    left: 0;
    right: 5px;
  }
}

.break-inside {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  page-break-inside: avoid;
}

.skillsTreeView {
  ul {
    list-style: none;

    ul {
      margin-left: calc(var(--gap) / 3);
    }

    > li {
      > ul {
        > li {
          @apply relative;

          padding: var(--top-gap) 0 0 var(--gap);
          &:last-child {
            &::before {
              content: "";
              @apply border-gray-300 border-l border-b rounded-bl-lg absolute left-0 top-0 hover:border-primary-400;
              width: calc(var(--gap) / 1.5);
              height: calc(var(--top-gap) + 1em / 2);
            }
          }

          &:not(:last-child) {
            // horizontal connector line
            @apply bg-no-repeat border-l border-gray-300 hover:border-primary-400 hover:bg-gradient-to-r from-primary-400 to-primary-400;
            background-image: linear-gradient(
              0,
              var(--border-color),
              var(--border-color)
            );
            background-position: 0 calc(var(--top-gap) + 1em / 2);
            background-size: calc(var(--gap) / 1.5) var(--border-width);
          }
        }
      }
    }
  }
}

.treeLinkButton {
  @apply block font-arabic text-gray-900 bg-white hover:bg-primary-400  font-medium rounded-lg px-5 py-2.5 me-2 mb-2 delay-75 max-w-md text-center;
}
